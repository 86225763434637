import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        width: "80vw",
        alignSelf: "center",
        overflow: "auto",
        height: "85%"
    },
    title: {
        fontSize: 14,
        fontWeight: "bold"
    },
    pos: {
        marginBottom: 12,
    },
    button: {
        margin: 10,
        marginTop: 0
    },
});

export default function TermsCard({url}: any) {
    const classes = useStyles();

    return (
        <>
        <Card className={classes.root}>
            <CardContent style={{paddingBottom:0}}>
                <Typography variant="body2" component="p" className={classes.pos}>
                    Read the Terms and Conditions for the shared data below.
                </Typography>
            </CardContent>
            <Button variant="contained" color="primary" className={classes.button} onClick={() => window.open(url)} size="small">Read More</Button>
        </Card>
        </>
    );
}